<template>
    <div>
        <BodycheckDaoru />
    </div>
</template>

<script>
import BodycheckDaoru from "./bodycheckDaoruView.vue";
export default {
    components: {
        BodycheckDaoru,
    },
    data() {
        return{
            
        }
    },
    created(){
        
    }
};
</script>
